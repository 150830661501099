import React, { useState, useEffect } from "react";
import "./App.scss";
import ThemeSwitcher from "./components/ThemeSwitcher";
import Logo from "./components/Logo";
import Achievements from "./components/Achievements";
import ParticleBackground from "react-particle-backgrounds";
import Socials from "./components/Socials";

// TODO
//  - лого дергается
//  - qr
//  - остальной текст

function App() {
  const [windowBottom, setWindowBottom] = useState(window.innerHeight);
  const [isDark, setIsDark] = useState(
    JSON.parse(localStorage.getItem("isDark")),
  );

  useEffect(() => {
    const onScroll = () => setWindowBottom(getWindowBottom());

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <div className={isDark ? "dark-theme" : "light-theme"}>
      <ParticleBackground
        className={"ParticleBackground"}
        settings={{
          particle: {
            particleCount: 35,
            color: isDark ? "white" : "black",
            minSize: 1,
            maxSize: 4,
          },
          velocity: {
            minSpeed: 0.2,
            maxSpeed: 0.4,
          },
          opacity: {
            minOpacity: 0,
            maxOpacity: 0.6,
            opacityTransitionTime: 10000,
          },
        }}
      />
      <div className={"layout"}>
        <div className={"header"}>
          <Socials />
          <ThemeSwitcher
            value={isDark}
            onChange={(value) => {
              setIsDark(value);
              localStorage.setItem("isDark", value);
            }}
          />
        </div>
        <Logo />
        <Achievements windowBottom={windowBottom} />
      </div>
    </div>
  );
}

export default App;

export const getWindowBottom = () => {
  const scrollY = window.scrollY;
  const innerHeight = window.innerHeight;
  return scrollY + innerHeight;
};
