import React from "react";
import { ReactComponent as Telegram } from "./../assets/telega.svg";
import { ReactComponent as Medium } from "./../assets/medium.svg";
import Discord from "./Discrod";

const Socials = () => {
  return (
    <div className={"socials"}>
      <a
        className={"icon"}
        href={"https://t.me/HEDONISM_team"}
        target={"_blank"}
      >
        <Telegram />
      </a>
      <a
        className={"icon"}
        href={"https://medium.com/@HedonismTeam"}
        target={"_blank"}
      >
        <Medium />
      </a>
      <Discord />
    </div>
  );
};
export default Socials;
