import React, { useRef } from "react";
import { motion } from "framer-motion";
import MotionValue from "./MotionValue";

const Achievement = ({ value, text, windowBottom }) => {
  const ref = useRef(null);

  const isVisible = (ref?.current?.offsetTop || 0) < windowBottom;

  return (
    <div ref={ref} className={"achievement-div"}>
      {isVisible ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            delay: 0,
            duration: 2,
            ease: [0, 0.71, 0.2, 1.01],
          }}
          className={"achievement"}
        >
          <div className={"achievement__value"}>
            <MotionValue value={value} />
          </div>
          <div className={"achievement__text"}>{text}</div>
        </motion.div>
      ) : null}
    </div>
  );
};

export default Achievement;
