import React from "react";
import { ReactComponent as Letter } from "./../assets/H.svg";
import { TypeAnimation } from "react-type-animation";

const Logo = () => {
  return (
    <div className={"logo"}>
      <div className={"letter"}>
        <Letter />
      </div>
      <div className={"letter-shadow"}>
        <Letter />
      </div>
      <div className={"hedonism"}>
        <TypeAnimation
          cursor={false}
          sequence={[1000, "HEDONISM"]}
          speed={20}
        />
      </div>
      <div className={"team"}>
        <TypeAnimation cursor={false} sequence={[2000, "TEAM"]} speed={5} />
      </div>
    </div>
  );
};

export default Logo;
