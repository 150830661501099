import React, { useState } from "react";
import { ReactComponent as Icon } from "./../assets/discord.svg";
import { TypeAnimation } from "react-type-animation";

const Discord = () => {
  const [overlay, setOverlay] = useState(false);

  return (
    <>
      <span className={"icon"} onClick={() => setOverlay((prev) => !prev)}>
        <Icon />
      </span>
      {overlay && (
        <div className={"popup"}>
          <span>
            <TypeAnimation
              cursor={false}
              sequence={[0, "INVITE CODE"]}
              speed={50}
            />
          </span>
        </div>
      )}
    </>
  );
};
export default Discord;
