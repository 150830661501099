import React from "react";
import { motion } from "framer-motion";

const ThemeSwitcher = ({ value, onChange }) => {
  return (
    <div
      className={"switch" + (value ? " switch_enabled" : "")}
      onClick={() => onChange(!value)}
    >
      <motion.div
        className="handle"
        layout
        transition={{
          type: "spring",
          stiffness: 700,
          damping: 30,
        }}
      />
    </div>
  );
};

export default ThemeSwitcher;
