import React from "react";
import Achievement from "./Achievement";

const Achievements = ({ windowBottom }) => {
  const achievements = [
    {
      value: 5,
      text: "years of experience",
    },
    { value: 15, text: "permanent team members" },
    { value: 50, text: "and over testnets" },
    { value: 140, text: "retro/airdrops from projects" },
  ];

  return (
    <div className={"achievements"}>
      {achievements.map((achievement, index) => (
        <Achievement {...achievement} key={index} windowBottom={windowBottom} />
      ))}
    </div>
  );
};

export default Achievements;
